import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import noImage from "../../../assets/images/noimg.png";
export const pettyCashSlice = createSlice({
  name: "pettyCash",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    imageLoading: false,
    model: false,
    focusPostModel:false,
    focusPostId:null,
    drawer: false,
    error_msg: null,
    listCount: 0,
    pettyCashList: [],
    pettyCashParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    rowdata: {
      items: [
        {
          _id: 0,
          item_id: 0,
          amount: "",
          bill_no: "",
          bill_date: "",
          date: "",
          remarks: "",
          dodelete: false,
          attachment: null,
        },
      ],
    },
    pettyCashParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    pettyCashViewData: {},
    viewModel: false,
    activePettyCashItemsCount: 1,
    ItemListImg: [],
    numberOfItems: 1,
    filterStatus: false,
  },
  reducers: {
    clearPettyList: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          items: [
            {
              _id: 0,
              item_id: "",
              amount: "",
              bill_no: "",
              bill_date: "",
              date: "",
              remarks: "",
              dodelete: false,
              attachment: null,
            },
          ],
        },
        activePettyCashItemsCount: 1,
        pettyCashViewData: {},
        pettyCashList: [],
        numberOfItems: 1,
      };
    },
    getPettyCashList: (state, action) => {
      if (state.pettyCashParams?.page == 1) {
        return {
          ...state,
          loading: true,
          pettyCashList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    pettyCashListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.pettyCashParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.pettyCashParams?.page_size
      );
      return {
        ...state,
        pettyCashList: list,
        listCount: action.payload.count,
        loading: false,
        pettyCashParams: {
          ...state.pettyCashParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetPettyCashParams: (state, action) => {
      return {
        ...state,
        pettyCashParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          pettyCashViewData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          pettyCashViewData: {},
        };
      }
    },
    focusPostModelVisible:(state,action) =>{
      if (action.payload) {
        return {
          ...state,
          focusPostModel: action.payload,
        };
      } else {
        return {
          ...state,
          focusPostModel: action.payload,
        };
      }
    },
    setFocusPostId : (state,action) =>{
      return{
        ...state,
        focusPostId:action.payload
      }
    },
    pettyCashAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    pettyCashAddSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          items: [
            {
              _id: 0,
              item_id: 0,
              amount: "",
              bill_no: "",
              bill_date: "",
              date: "",
              remarks: "",
              dodelete: false,
            },
          ],
        },
      };
    },
    getPettyCashByID: (state) => {
      return {
        ...state,
        loading: true,
        rowdata: {},
      };
    },
    pettyCashByIDSuccessful: (state, action) => {


      var pettyItems = action.payload.response.items?.map((item, i) => {
        return {
          _id: item.id,
          id: item.id,
          item_id: item.item?.id,
          amount: item.amount,
          bill_no: item.bill_no,
          bill_date: moment(item.bill_date),
          date: moment(item.date),
          remarks: item.remarks,
          dodelete: false,
          attachment: item.attachment,

        };
      });
      var numberOfItems = pettyItems.length;
      return {
        ...state,
        loading: false,
        numberOfItems: numberOfItems,
        rowdata: {
          id: action.payload.response.id,
          amount: action.payload.response.amount,
          bill_no: action.payload.response.bill_no,
          biometric_id: action.payload.response.biometric_id,
          biometric_name: action.payload.response.biometric_name,
          branch_id: action.payload.response.branch?.id,
          remarks: action.payload.response.remarks,
          attachment: action.payload.response.attachment,
          attachment_id: action.payload.response.attachment?.id,
          // previewImg: previewImg,
          date:
            action.payload.response.date == null
              ? null
              : moment(action.payload.response.date),
          bill_date:
            action.payload.response.bill_date == null
              ? null
              : moment(action.payload.response.bill_date),
          date:
            action.payload.response.date == null
              ? null
              : moment(action.payload.response.date),
          items: pettyItems,
        },
        activePettyCashItemsCount: pettyItems.length,
        pettyCashViewData: action.payload.response,
      };
    },

    pettyCashDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    isViewModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          viewModel: action.payload,
          pettyCashViewData: {},
        };
      } else {
        return {
          ...state,
          viewModel: action.payload,
        };
      }
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          pettyCashViewData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        pettyCashParams: {
          ...state.pettyCashParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    // PettyItemInputChangeValue: (state, action) => {
    //   var Cashtems = [];
    //   Cashtems = state.rowdata.items.map((Cashtems) => {
    //     if (Cashtems.index == action.payload.index) {
    //       if (action.payload.key == "item") {
    //         return {
    //           ...Cashtems,
    //           item: { id: action.payload.value, name: action.payload.item },
    //         };
    //       } else {
    //         return {
    //           ...Cashtems,
    //           [action.payload.key]: action.payload.value,
    //         };
    //       }
    //     }
    //     return Cashtems;
    //   });
    //   return {
    //     ...state,
    //     loading: false,
    //     rowdata: {
    //       ...state.rowdata,
    //       items: Cashtems,
    //     },
    //   };
    // },

    PettyItemInputChangeValue: (state, action) => {
      var Cashtems = state.rowdata.items.map((petty) => {
        if (petty._id == action.payload.item._id) {
          return {
            ...petty,
            [action.payload.key]: action.payload.value,
          };
        }
        return petty;
      });
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          items: Cashtems,
        },
      };
    },

    removeCardItem: (state, action) => {
      let selectedPettyItem = [];
      if (
        !action.payload.id
      ) {
        selectedPettyItem = state.rowdata.items.filter(
          (item, _id) => {
            return item._id != action.payload._id
          }
        );
      } else {
        selectedPettyItem = state.rowdata.items.map((item) => {
          if (item.id == action.payload.id) {
            return { ...item, dodelete: true };
          }
          return item;
        });
      }

      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          items: selectedPettyItem,
        },
        numberOfItems: selectedPettyItem.length,
        activePettyCashItemsCount: selectedPettyItem.length
      };
    },

    // Add Petty cash Items

    AddPettyCashItemRow: (state, action) => {
      if (state.numberOfItems >= 10) {
        // alert('Cannot add more than 10 items.');
        return state;
      }
      return {
        ...state,
        numberOfItems: state.numberOfItems + 1,
        rowdata: {
          ...state.rowdata,
          items: [
            ...state.rowdata.items,
            {
              _id: state.numberOfItems + 1,
              item: {},
              bill_no: "",
              bill_date: "",
              amount: "",
              remarks: "",
              dodelete: false,
              attachment: null
            },
          ],
        },
        activePettyCashItemsCount:
          parseInt(state.activePettyCashItemsCount) + 1,
      };
    },
    // RemovePettyCashItemtRow: (state, action) => {
    //   var PettyCashItemList = [];
    //   if (action.payload.id != null) {
    //     PettyCashItemList = state.rowdata.items.map((reItem) => {
    //       if (reItem.index == action.payload.index) {
    //         return { ...reItem, dodelete: true };
    //       }
    //       return reItem;
    //     });
    //   } else {
    //     PettyCashItemList = state.rowdata.items.filter((reItem) => {
    //       return reItem.index != action.payload.index;
    //     });
    //   }

    //   var activeList = PettyCashItemList.filter((item) => {
    //     return !item.dodelete;
    //   });
    //   return {
    //     ...state,
    //     rowdata: {
    //       ...state.rowdata,
    //       items: PettyCashItemList,
    //     },
    //     activePettyCashItemsCount: activeList.length,
    //   };
    // },

    // getPettyItemImageUpload: (state, action) => {
    //   return {
    //     ...state,
    //     loading: true,
    //     imageLoading: true,
    //     rowdata: {
    //       ...state.rowdata,
    //       [action.payload.imageType]: null,
    //     },
    //   };
    // },
    // pettyItemImageUploadSuccessful: (state, action) => {
    //   return {
    //     ...state,
    //     loading: false,
    //     imageLoading: false,
    //     rowdata: {
    //       ...state.rowdata,
    //       [action.payload.ImageType]: action.payload.response.data,
    //       [action.payload.ImageType + "_id"]: action.payload.response.data.id,
    //     },
    //   };
    // },
    pettyImageUpload: (state, action) => {
      return {
        ...state,
        imageLooading: true,
        ItemListImg: [...state.ItemListImg, action.payload],
      };
    },
    // bannerImgUpload: (state, action) => {
    //   return {
    //     ...state,
    //     imageLoading: false,
    //     rowdata: {
    //       ...state.rowdata,
    //       [action.payload.imageType]: action.payload.file,
    //     },
    //   };
    // },
    // setPreviewImg: (state, action) => {
    //     return{
    //       ...state,
    //       loading: false,
    //       rowdata: {
    //         ...state.rowdata,
    //         previewFile: action.payload,
    //       }
    //     }
    // },
    // checkTotalAmount: (state) => {
    //   var pettyAmount = state.rowdata?.items.filter(
    //     (item) => item.dodelete == false
    //   );
    //   const sum = pettyAmount.reduce(
    //     (partialSum, a) =>
    //       partialSum + parseInt(a.amount == "" ? "0" : a.amount),
    //     0
    //   );

    //   return {
    //     ...state,
    //     loading: false,
    //     rowdata: {
    //       ...state.rowdata,
    //       amount: sum,
    //     },
    //   };
    // },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getPettyCashList,
  pettyCashListSuccessful,
  SetPettyCashParams,
  pettyCashAdd,
  pettyCashAddSuccessful,
  getPettyCashByID,
  pettyCashByIDSuccessful,
  pettyCashDelete,
  InputChangeValue,
  isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  isViewModelVisible,
  focusPostModelVisible,
  setFocusPostId,
  clearPettyList,
  PettyItemInputChangeValue,
  AddPettyCashItemRow,
  RemovePettyCashItemtRow,
  // checkTotalAmount,
  // getPettyItemImageUpload,
  // pettyItemImageUploadSuccessful,
  removeCardItem,
  pettyImageUpload,


  // bannerImgUpload,
  // setPreviewImg,
  fsyncStatusUpdate,
  checkFilterStatus,
  apiError,
} = pettyCashSlice.actions;

export default pettyCashSlice.reducer;
